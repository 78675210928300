import React from "react";
import F1 from "../../assets/img/Landing page-slicing/message-call.png";
import F2 from "../../assets/img/Landing page-slicing/audio-call.png";
import F3 from "../../assets/img/Landing page-slicing/video-call.png";
import F4 from "../../assets/img/Landing page-slicing/push-talk.png";
import { Col, Row } from "reactstrap";

const Features = () => {
  return (
    <div className="bg-white pt-4 pb-4">
      <div className="bg-white about2 pt-5">
        <h1
          className="font-family-2 text-center py-4"
          style={{ fontWeight: "700" }}
        >
          Communication Features
        </h1>
        <Row md="4" sm="1" xs="1" className="img-div pt-4 pb-5 mb-0">
          <Col className="px-4">
            <img src={F1} />
            <h4 className="text-center pt-3" style={{ fontWeight: "700" }}>
              Message
            </h4>
          </Col>
          <Col className="px-4">
            <img src={F2} />
            <h4 className="text-center pt-3" style={{ fontWeight: "700" }}>
              Audio Call
            </h4>
          </Col>
          <Col className="px-4">
            <img src={F3} />
            <h4 className="text-center pt-3" style={{ fontWeight: "700" }}>
              Video Call
            </h4>
          </Col>
          <Col className="px-4">
            <img src={F4} />
            <h4 className="text-center pt-3" style={{ fontWeight: "700" }}>
              Push to Talk
            </h4>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Features;
