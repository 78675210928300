import React from "react";
import { Col, Row } from "reactstrap";
import Homepage from "../../assets/img/lotties/gemini.json";
import Lottie from "react-lottie";

const Gemini = () => {
  return (
    <div className="bg-white pt-4 pb-4">
      <div className="bg-white about2 pt-5">
        <h1
          className="font-family-2 text-center pt-4 pb-2"
          style={{ fontWeight: "700" }}
        >
          Powered by Google Gemini
        </h1>
        <Row md="2" xs="1" className="img-div mb-0 px-5">
          <Col className="pt-5">
            <p>
              Merk Mobile integrated with Google Gemini to offer a seamless Al
              powered chat for field workers and mobile teams. With built in Al
              chat, team members can access Gemini directly to get real time
              assistance, answer questions, or troubleshoot common issues. The
              Al experience is designed for quick, simple interactions, with a
              focus on keeping conversations efficient and easy to reference
              through a chat history that logs previous interactions. This setup
              allows field workers to get support without leaving their
              workflow, making tasks smoother and helping resolve challenges.
            </p>
          </Col>
          <Col>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: Homepage,
              }}
              height={275}
              width={275}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Gemini;
